import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ImgContainer = styled.div`
  width: 30%;
`;

const EventsGallery = ({ data, imageRef }) => {
  const imgToDisplay = data[imageRef].edges;

  return (
    <Container>
      {imgToDisplay.map((img, i) => (
        <ImgContainer key={i}>
          <Img className="image" fluid={img.node.childImageSharp.fluid} />
        </ImgContainer>
      ))}
    </Container>
  );
};

export default EventsGallery;
