import React from "react";
import styled from "styled-components";

import EventsGallery from "src/components/eventsGallery";

import MicrosoftLogo from "src/images/sponsors/microsoft.png";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: black;
  position: absolute;
  margin-top: 50px;
  box-sizing: border-box;
  padding: 0 25px;

  @media (max-width: 500px) {
    margin-top: 80px;
    padding: 0 25px;
    height: 90%;
  }
`;

const Wrapper = styled.div`
  width: 650px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const Title = styled.h1`
  font-family: "URWAccidalia", sans-serif;
  font-size: 38px;
  color: white;

  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const Subtitle = styled.h3`
  font-family: "URWAccidalia", sans-serif;
  font-size: 18px;
  color: white;
  margin-top: 5px;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const Description = styled.p`
  font-family: "Univers", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  color: white;
  margin-top: 20px;

  @media (max-width: 500px) {
    margin-top: 10px;
    font-size: 12px;
  }

  @media (max-height: 500px) {
    font-size: 10px;
  }
`;

const ImageWrapper = styled.div`
  margin-top: 10px;
`;

const BackButton = styled.button`
  margin: 0 0 15px 0;
  padding: 0;
  font-family: "URWAccidalia", sans-serif;
  color: white;
  font-size: 16px;
  font-weight: 700;
  background: none;
  border: none;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const PoweredByWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  img {
    width: 100px;
  }

  @media (max-width: 500px) {
    margin-top: 5px;
  }
`;

const PoweredByText = styled.p`
  font-family: "URWAccidalia", sans-serif;
  font-size: 12px;
  color: white;
`;

const EventModal = ({
  title,
  subtitle,
  sponsoredLogo,
  gatsbyRef,
  description,
  toggleModal,
  showModal,
  data,
}) => {
  return (
    <Container
      id="modal"
      onClick={e => {
        if (e.target.id === "modal") {
          toggleModal();
        }
      }}
      showModal={showModal}
    >
      <Wrapper>
        <BackButton onClick={toggleModal}>BACK</BackButton>
        <Title>{title} --</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Description>{description}</Description>
        <ImageWrapper>
          <EventsGallery data={data} imageRef={gatsbyRef} />
        </ImageWrapper>
        {sponsoredLogo ? (
          <PoweredByWrapper>
            <PoweredByText>Powered by: </PoweredByText>
            <img src={MicrosoftLogo} alt="Microsoft Logo" />
          </PoweredByWrapper>
        ) : null}
      </Wrapper>
    </Container>
  );
};

export default EventModal;
