import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { TweenMax, TimelineMax, Power0, Power4 } from "gsap/TweenMax";
import { Draggable } from "gsap/Draggable";

// import Tilt from "react-tilt";

import useWindowSize from "src/hooks/useWindowSize";

import copy from "src/copy";

import Right from "src/images/chevrons/right-chevron.png";
import Left from "src/images/chevrons/left-chevron.png";

import EventCube from "src/components/eventCube";
import EventModal from "src/components/eventModal";
import SEO from "src/components/seo.js";
import { graphql } from "gatsby";

const TitleContainer = styled.div`
  display: flex;
  height: 100vh;
  position: absolute;
  align-items: center;
`;

const PageName = styled.h1`
  writing-mode: vertical-rl;
  -webkit-text-stroke: 1px white;
  color: transparent;
  font-family: "URWAccidalia", sans-serif;
  font-size: 42pt;
  padding-left: 16px;
  z-index: 1000;
  opacity: 0;

  @media (max-width: 1150px) {
    display: none;
  }
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  perspective: 700px;
  transform: translate3d(0px, 0px, 0px);
  background: "rgba(255, 255, 255, .4)";
  opacity: 0;
  visibility: hidden;
`;

const Scene3D = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  transform: translateZ(0px);
`;

const ButtonSection = styled.div`
  position: fixed;
  z-index: 500;
  left: 35%;
  bottom: 140px;
  width: 30%;
  display: flex;
  justify-content: center;
  align-content: center;
  opacity: 0;
  @media (max-width: 1000px) {
    bottom: 140px;
    right: 0;
    left: 0;
    width: 100%;
  }
  @media (max-width: 500px) {
    bottom: 100px;
  }
  @media (max-width: 350px) {
    bottom: 80px;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  color: ${props => (props.hightlighted ? `#fff` : `grey`)};
  font-size: 18px;
  font-family: "URWAccidalia";
  margin-top: 20px;
  margin-right: 10px;
  margin-left: 10px;
  border: none;
  background: none;
  @media (max-width: 800px) {
    font-size: 18px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
    margin-right: 5px;
    margin-left: 5px;
  }
  transition: color 0.5s ease;
  &:hover {
    color: ${props => (props.highlighted ? "grey" : "#EEEEEE")};
  }
`;

// const SpecialContainer = styled.div`
//   position: absolute;
//   transform-style: preserve-3d;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: rgb(0, 0, 0, 0);
//   opacity: 0;

//   div:first-child {
//     padding-left: 25px;
//   }

//   div:last-child {
//     padding-right: 25px;
//   }

//   @media (max-width: 500px) {
//     flex-direction: column;

//     div:first-child {
//       padding-left: 0;
//     }

//     div:last-child {
//       padding-right: 0;
//     }

//     a:last-child {
//       margin-bottom: 50px;
//     }
//   }
// `;

// const SpecialEventImgWrapper = styled(Tilt)`
//   max-width: 330px;
//   margin-bottom: 100px;

//   &:first-child {
//     margin-right: 40px;
//   }

//   @media (min-width: 1440px) {
//     max-width: 400px;
//   }
// `;

// const SpecialEventImgMobileWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   margin-bottom: 5px;
// `;

// const SpecialEventImg = styled.img`
//   width: 100%;
//   cursor: pointer;

//   @media (max-width: 500px) {
//     width: 88vw;
//     height: 40%;
//   }
// `;

const PastContainer = styled.div`
  position: absolute;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  transform: translateZ(-5000px) translateY(-100px);
  @media (max-width: 800px) {
    transform: translateZ(-5000px) translateY(-80px);
  }
`;

const Overlay = styled.div`
  position: absolute;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.5);
  opacity: 0;
`;

const CurrentContainer = styled.div`
  position: absolute;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0, 0, 0, 0);
  opacity: 0;
  transform: translateZ(-5000px);
`;

const EventsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props =>
    props.currentEvents.length > 2 ? `flex-start` : `center`};
  width: 80%;
  overflow-y: scroll;
  height: 60%;
  background: rgba(0, 0, 0, 0);
  margin-bottom: 75px;
  padding-top: 3px;
  @media (max-width: 1000px) {
    width: 90%;
  }
  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 135px;
  }
  @media (max-width: 500px) {
    height: 55%;
    margin-bottom: 75px;
  }
  @media (min-height: 550px) and (max-width: 500px) {
    height: 60%;
    margin-bottom: 55px;
  }
  @media (min-height: 600px) and (max-width: 500px) {
    height: 52%;
    margin-bottom: 50px;
  }
  @media (min-height: 800px) and (min-width: 900px) {
    height: 60%;
    margin-bottom: 75px;
  }
`;

const EventContainer = styled.div`
  margin-bottom: 50px;
  width: 70%;
  display: grid;
  grid-template-columns: 20% 60% 20%;
  grid-template-rows: auto;
  grid-gap: 30px 0px;
  grid-template-areas: "DATE TITLE RSVP";
  justify-content: flex-start;
  align-content: center;
  background: rgb(0, 0, 0, 0);
  @media (max-width: 1200px) {
    width: 80%;
  }
  @media (max-width: 1100px) {
    width: 85%;
  }
  @media (max-width: 800px) {
    grid-template-columns: 40% 40% 20%;
    grid-template-areas:
      "DATE . RSVP"
      "TITLE TITLE .";
    width: 85%;
    margin-bottom: 50px;
    grid-gap: 20px 0px;
  }
  @media (max-width: 500px) {
    margin-bottom: 20px;
    grid-gap: 10px 0px;
  }
  @media (max-width: 330px) {
    margin-bottom: 20px;
    grid-gap: 5px 0px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const EventDate = styled.p`
  grid-area: DATE;
  justify-self: flex-start;
  color: #fff;
  font-size: 18px;
  font-family: "URWAccidalia";
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
  text-transform: uppercase;
  @media (max-width: 1000px) {
    font-size: 18px;
  }
  @media (max-width: 800px) {
    font-size: 18px;
    line-height: 1;
    align-self: flex-end;
  }
  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 1;
  }
  @media (max-width: 330px) {
    font-size: 8px;
  }
`;

const NoEvents = styled.div`
  width: 50%;
  justify-content: flex-start;
  align-content: center;
`;

const NoEventsTitle = styled.div`
  font-family: "URWAccidalia";
  color: white;
  font-size: 14px;
  text-align: center;
`;

const EventTitle = styled.p`
  grid-area: TITLE;
  justify-self: start;
  color: #fff;
  font-size: 18px;
  font-family: "URWAccidalia";
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
  text-transform: uppercase;
  @media (max-width: 1000px) {
    font-size: 18px;
  }
  @media (max-width: 800px) {
    font-size: 18px;
    justify-self: start;
  }
  @media (max-width: 500px) {
    font-size: 14px;
  }
  @media (max-width: 330px) {
    font-size: 10px;
  }
`;

const RSVPButton = styled.a`
  color: white;
  font-size: 18px;
  font-family: "URWAccidalia";
  padding: 6px;
  background: black;
  border: 1px solid white;
  line-height: 1.2;
  transition: color 0.5s ease, background 0.5s ease;
  @media (max-width: 1000px) {
    font-size: 18px;
    padding: 10px;
  }
  @media (max-width: 800px) {
    font-size: 18px;
    padding: 6px;
  }
  @media (max-width: 500px) {
    font-size: 12px;
    padding: 3px;
  }
  @media (max-width: 330px) {
    font-size: 8px;
    padding: 2px;
  }
  &:hover {
    color: black;
    background: white;
  }
  justify-self: end;
  align-self: center;
  grid-area: RSVP;
  @media (max-width: 800px) {
    align-self: flex-end;
  }
`;

const Mask = styled.div`
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Carousel = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Cube = styled.div`
  transition: none;
  transform-style: preserve-3d;
  position: absolute;
`;

const InnerCube = styled.div`
  transition: none;
  transform-style: preserve-3d;
`;

const InfoContainer = styled.div`
  margin-top: 375px;
  width: 400px;
  height: 75px;
  display: grid;
  z-index: 99900000;
  justify-content: center;
  grid-template-columns: 15px 300px 15px;
  @media (max-width: 500px) {
    margin-top: 300px;
    grid-template-columns: 15px 250px 15px;
  }
  @media (max-height: 600px) {
    margin-top: 300px;
  }
  @media (max-height: 575px) and (max-width: 500px) {
    margin-top: 275px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  justify-self: center;
`;

const Header = styled.h1`
  transition: all 1s ease;
  color: white;
  font-family: "URWAccidalia";
  font-size: 16px;
  width: 210px;
  text-align: center;
  line-height: 1.2;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const SubHeader = styled.p`
  transition: all 1s ease;
  color: white;
  font-family: "URWAccidalia";
  font-size: 10px;
  margin-top: 5px;
  text-transform: uppercase;

  @media (max-width: 500px) {
    font-size: 9px;
  }
`;

const Chevron = styled.img`
  pointer-events: auto;
  user-select: none;
  justify-self: center;
  align-self: center;
  padding: 30px 0 30px 0;
  touch-action: manipulation;
  width: 25px;
  &:hover {
    cursor: pointer;
  }
`;

// Calculation to determine radius of carousel (400 is space of cube and margin)
const tz = Math.round(400 / 2 / Math.tan(Math.PI / copy.pastEvents.length));
let isTouch = true;

// Events Component
const EventsPage = ({ data }) => {
  const { width, height } = useWindowSize();
  // States and References to keep track of state
  const [middleCube, setMiddleCube] = useState(0);
  const swipeTimelineRef = useRef();
  const queueTimelineRef = useRef();
  const [priorityTween, setPriority] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const isZoomedRef = useRef(isZoomed);
  const prevMiddleCube = useRef(middleCube);
  const lastDragRef = useRef(0);
  const [isTouchDevice, setTouchDevice] = useState(false);

  const [showModal, setShowModal] = useState(false);

  let currentEvents = [...data.currentEvents.edges];
  currentEvents.splice(0, 1);

  useEffect(() => {
    isZoomedRef.current = isZoomed;
  }, [isZoomed]);

  // Functions for zooming between past and current events
  const zoomIntoPast = () => {
    setIsZoomed(true);
    TweenMax.to("#scene3d", 1, { z: 5000 });
    TweenMax.to("#gradientMask", 0.75, { autoAlpha: 1, delay: 0.75 });
  };
  const zoomOutCurrent = () => {
    setShowModal(false);
    setIsZoomed(false);
    TweenMax.to("#scene3d", 1, { z: "0" });
    TweenMax.to("#gradientMask", 0.5, { autoAlpha: 0 });
  };
  // const zoomOutSpecial = () => {
  //   setShowModal(false);
  //   setIsZoomedTo("special");
  //   TweenMax.to("#scene3d", 1, { z: "0" });
  // };

  // componentDidMount for animations
  useEffect(() => {
    TweenMax.set("#currentContainer", { z: -4000 });
    TweenMax.set("#overlay", { z: -4000 });
    TweenMax.to("#currentContainer", 1, { z: 0, autoAlpha: 1 });
    TweenMax.to("#overlay", 1, { z: -1, autoAlpha: 1 });

    // GSAP timeline that controls constant rotation of each cube
    const rotationTL = new TimelineMax({ repeat: -1 });

    // Map through past events to set carousel and animations
    copy.pastEvents.map((obj, num) => {
      // Setting up cubes into carousel
      TweenMax.set(`#cube${num}`, {
        rotationY: `${num * (360 / copy.pastEvents.length) -
          Math.round(copy.pastEvents.length / 2) *
            (360 / copy.pastEvents.length)}`,
        transformOrigin: `50% 50% -${tz}`,
      });
      //If even number of cubes, rotate every cube to ensure cube in center
      if (copy.pastEvents.length % 2 !== 0) {
        TweenMax.set(`#cube${num}`, {
          rotationY: `+=${360 / copy.pastEvents.length / 2}`,
        });
      }
      // Set random rotation to each cube to start with
      TweenMax.set(`#EventCube${num}`, {
        rotationY: `${Math.random() * 90}`,
        rotationX: `${Math.random() * 90}`,
      });
      // Set constant rotation of each cube
      rotationTL.to(
        `#EventCube${num}`,
        100,
        {
          rotationY: `${Math.random() > 0.5 ? "+=" : "-="}360`,
          rotationX: `${Math.random() > 0.5 ? "+=" : "-="}360`,
          ease: Power0.easeNone,
        },
        0
      );
      return null;
    });

    // Move carousel to see only three cubes from inside
    TweenMax.set("#carousel", { z: `${2 * tz}` });

    TweenMax.set("#SectionTitle", { y: -200, autoAlpha: 0 });
    TweenMax.to("#SectionTitle", 1, {
      y: 0,
      autoAlpha: 1,
      ease: Power4.easeInOut,
    });
    TweenMax.to("#container", 1.25, { autoAlpha: 1, ease: Power4.easeInOut });
    TweenMax.to("#buttonSection", 1.25, {
      autoAlpha: 1,
      ease: Power4.easeInOut,
    });

    const swipeTimeline = new TimelineMax({ paused: true, repeat: -1 });

    // Map through past events to make each cube draggable
    copy.pastEvents.map((obj, num) => {
      // Set up GSAP draggable on every cube
      Draggable.create(`#EventCube${num}`, {
        allowEventDefault: true,
        bounds: {
          minX: 0,
          maxX: 0,
          minY: 0,
          maxY: 0,
        },
        onPress() {
          this.prev = this.pointerX;
        },
        onRelease() {
          if (this.prev - this.pointerX > 5) {
            rotateForward();
            lastDragRef.current = Date.now();
          } else if (this.prev - this.pointerX < -5) {
            rotateBackward();
            lastDragRef.current = Date.now();
          }
        },
      });

      swipeTimeline.fromTo(
        `#cube${num}`,
        copy.pastEvents.length,
        { rotationY: `-=0`, ease: Power0.easeNone },
        {
          rotationY: `-=360`,
          ease: Power0.easeNone,
        },
        0
      );

      return null;
    });
    swipeTimelineRef.current = swipeTimeline;

    const queueTimeline = new TimelineMax({
      paused: true,
    });
    queueTimelineRef.current = queueTimeline;

    if (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    ) {
      setTouchDevice(true);
    }
  }, []);

  useEffect(() => {
    if (
      prevMiddleCube.current === 0 &&
      middleCube === copy.pastEvents.length - 1
    ) {
      // swipeTimelineRef.current
      //   .tweenTo(copy.pastEvents.length - 1, { ease: Power1.easeInOut })
      //   .duration(0.75);
      TweenMax.killTweensOf(swipeTimelineRef.current);
      swipeTimelineRef.current.time(copy.pastEvents.length);
      queueTimelineRef.current.to(swipeTimelineRef.current, 0.75, {
        time: copy.pastEvents.length - 1,
        onComplete: () => {
          setPriority(false);
        },
      });
      setPriority(true);
    } else if (
      prevMiddleCube.current === copy.pastEvents.length - 1 &&
      middleCube === 0
    ) {
      // swipeTimelineRef.current
      //   .tweenTo(copy.pastEvents.length, { ease: Power1.easeInOut })
      //   .duration(0.75)
      //   .eventCallback("onComplete", () => {
      //     swipeTimelineRef.current.time(0);
      //   });

      queueTimelineRef.current.to(swipeTimelineRef.current, 0.75, {
        time: copy.pastEvents.length,
        onComplete: () => {
          swipeTimelineRef.current.time(0);
          setPriority(false);
        },
      });

      setPriority(true);
    } else {
      if (priorityTween === false) {
        queueTimelineRef.current.clear();
      }
      queueTimelineRef.current.to(swipeTimelineRef.current, 0.75, {
        time: middleCube,
      });
      queueTimelineRef.current.play();
    }

    prevMiddleCube.current = middleCube;
  }, [middleCube]);

  if (typeof window !== `undefined` && "ontouchstart" in window) {
    isTouch = true;
  }

  const rotateForward = () => {
    setMiddleCube(prev => {
      if (prev === 0) {
        return copy.pastEvents.length - 1;
      } else {
        return prev - 1;
      }
    });
  };

  const rotateBackward = () => {
    setMiddleCube(prev => {
      if (prev === copy.pastEvents.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  const rotateTo = num => {
    if (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    )
      return;

    if (Date.now() - lastDragRef.current < 1000) return;
    setMiddleCube(num);
  };
  // Return components and HTML

  const toggleModal = () => {
    setShowModal(prev => !prev);
  };

  useEffect(() => {
    if (showModal) {
      TweenMax.to("#modal", 0.25, { autoAlpha: 1 });
      TweenMax.to("#mask", 0.25, { autoAlpha: 0 });
      TweenMax.to("#carousel", 1, { visibility: "hidden" });
    } else {
      TweenMax.to("#modal", 0.25, { autoAlpha: 0 });
      TweenMax.to("#mask", 0.25, { autoAlpha: 1 });
      TweenMax.to("#carousel", 1, { visibility: "visible" });
    }
  }, [showModal]);

  return (
    <>
      <SEO title="HXOUSE · Events" />
      <TitleContainer id="TitleContainer">
        <PageName id="SectionTitle"> EVENTS </PageName>
      </TitleContainer>
      <ButtonSection id="buttonSection">
        <ButtonWrapper>
          <Button hightlighted={!isZoomed} onClick={zoomOutCurrent}>
            CURRENT
          </Button>
          <Button hightlighted={isZoomed} onClick={zoomIntoPast}>
            PAST
          </Button>
        </ButtonWrapper>
      </ButtonSection>

      <Container id="container">
        <Scene3D id="scene3d">
          <CurrentContainer id="currentContainer">
            <EventsSection currentEvents={currentEvents} id="eventsSection">
              {currentEvents.length !== 0 ? (
                currentEvents.map((obj, index) => (
                  <EventContainer key={index}>
                    <EventDate>{obj.node.date}</EventDate>
                    <EventTitle>{obj.node.name}</EventTitle>

                    <RSVPButton
                      href={obj.node.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      RSVP
                    </RSVPButton>
                  </EventContainer>
                ))
              ) : (
                <NoEvents>
                  <NoEventsTitle>NO CURRENT EVENTS</NoEventsTitle>
                </NoEvents>
              )}
            </EventsSection>
          </CurrentContainer>

          <Overlay id="overlay" />
          <PastContainer id="pastContainer">
            <EventModal
              data={data}
              title={copy.pastEvents[middleCube].heading}
              subtitle={copy.pastEvents[middleCube].subheading}
              description={copy.pastEvents[middleCube].description}
              sponsoredLogo={copy.pastEvents[middleCube].sponsoredLogo}
              gatsbyRef={copy.pastEvents[middleCube].gatsbyRef}
              showModal={showModal}
              toggleModal={toggleModal}
            />
            <Mask id="mask">
              <InfoContainer>
                <Chevron onClick={rotateBackward} src={Left} />
                <TextContainer>
                  <Header>{copy.pastEvents[middleCube].heading}</Header>
                  <SubHeader>
                    {copy.pastEvents[middleCube].subheading}
                  </SubHeader>
                </TextContainer>
                <Chevron onClick={rotateForward} src={Right} />
              </InfoContainer>
            </Mask>
            <Carousel id="carousel">
              {copy.pastEvents.map((obj, num) => {
                if (isTouchDevice) {
                  return (
                    <Cube
                      id={`cube${num}`}
                      key={`cube${num}`}
                      onTouchEnd={() =>
                        middleCube === num ? toggleModal() : rotateTo(num)
                      }
                    >
                      <InnerCube key={num} id={`EventCube${num}`}>
                        <EventCube
                          isZoomed={isZoomed}
                          image={obj.image}
                          middle={middleCube === num}
                          num={num}
                          size={width > 500 ? 150 : height > 575 ? 125 : 100}
                        />
                      </InnerCube>
                    </Cube>
                  );
                } else {
                  return (
                    <Cube
                      id={`cube${num}`}
                      key={`cube${num}`}
                      onClick={() =>
                        middleCube === num ? toggleModal() : rotateTo(num)
                      }
                    >
                      <InnerCube key={num} id={`EventCube${num}`}>
                        <EventCube
                          isZoomed={isZoomed}
                          image={obj.image}
                          middle={middleCube === num}
                          num={num}
                          size={width > 500 ? 150 : height > 575 ? 125 : 100}
                        />
                      </InnerCube>
                    </Cube>
                  );
                }
              })}
            </Carousel>
          </PastContainer>
        </Scene3D>
      </Container>
    </>
  );
};

export default EventsPage;

export const query = graphql`
  query {
    # errolsonworkshop
    errolsonworkshop: allFile(
      filter: {
        relativePath: { regex: "/pastevents/errolsonWorkshop/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(
              maxWidth: 200
              maxHeight: 200
              cropFocus: CENTER
              fit: COVER
              quality: 60
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # openhxouse
    openhxouse: allFile(
      filter: {
        relativePath: { regex: "/pastevents/openHxouse/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(
              maxWidth: 200
              maxHeight: 200
              cropFocus: CENTER
              fit: COVER
              quality: 60
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # hxouselive
    hxouselive: allFile(
      filter: {
        relativePath: { regex: "/pastevents/hxouseLive/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(
              maxWidth: 200
              maxHeight: 200
              cropFocus: CENTER
              fit: COVER
              quality: 60
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # devlinconversation
    devlinconversation: allFile(
      filter: {
        relativePath: { regex: "/pastevents/devlinConversation/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(
              maxWidth: 200
              maxHeight: 200
              cropFocus: CENTER
              fit: COVER
              quality: 60
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # femaleentrepreneurship
    femaleentrepreneurship: allFile(
      filter: {
        relativePath: { regex: "/pastevents/femaleEntrepreneurship/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(
              maxWidth: 200
              maxHeight: 200
              cropFocus: CENTER
              fit: COVER
              quality: 60
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # engineears
    engineears: allFile(
      filter: {
        relativePath: { regex: "/pastevents/engineEars/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(
              maxWidth: 200
              maxHeight: 200
              cropFocus: CENTER
              fit: COVER
              quality: 60
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # strangeloops
    strangeloops: allFile(
      filter: {
        relativePath: { regex: "/pastevents/strangeLoops/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(
              maxWidth: 200
              maxHeight: 200
              cropFocus: CENTER
              fit: COVER
              quality: 60
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # metroboomin
    metroboomin: allFile(
      filter: {
        relativePath: { regex: "/pastevents/metroBoomin/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(
              maxWidth: 200
              maxHeight: 200
              cropFocus: CENTER
              fit: COVER
              quality: 60
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # lala
    lala: allFile(
      filter: {
        relativePath: { regex: "/pastevents/laLa/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(
              maxWidth: 200
              maxHeight: 200
              cropFocus: CENTER
              fit: COVER
              quality: 60
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # rhuigi
    rhuigi: allFile(
      filter: {
        relativePath: { regex: "/pastevents/rhuigi/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(
              maxWidth: 200
              maxHeight: 200
              cropFocus: CENTER
              fit: COVER
              quality: 60
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # safdie
    safdie: allFile(
      filter: {
        relativePath: { regex: "/pastevents/safdie/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(
              maxWidth: 200
              maxHeight: 200
              cropFocus: CENTER
              fit: COVER
              quality: 60
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }
    currentEvents: allGoogleEventsSheet {
      edges {
        node {
          date
          name
          url
        }
      }
    }
  }
`;
