import React, { useEffect } from "react";
import styled from "styled-components";
import TweenMax from "gsap/TweenMaxBase";

const SmallContainer = styled.div`
  transform-style: preserve-3d;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
`;

const CubeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
`;

//adjust size of face based on screen size
const CubeFace1 = styled.div`
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${props => `translateZ(${props.size / 2 + 1}px)`};
  background: ${props => `url(${props.image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: ${props =>
    props.middle ? `solid 1.5px #fff` : `solid 1.5px #424242`};
  transition: border 1s ease;
  position: absolute;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  backface-visibility: hidden;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0);
  outline: 2px solid transparent;
`;

const CubeFace2 = styled.div`
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${props =>
    `rotateX(180deg) rotateZ(180deg) translateZ(${props.size / 2 + 1}px)`};
  background: ${props => `url(${props.image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: ${props =>
    props.middle ? `solid 1.5px #fff` : `solid 1.5px #424242`};
  transition: border 1s ease;
  position: absolute;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  backface-visibility: hidden;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0);
  outline: 2px solid transparent;
`;

const CubeFace3 = styled.div`
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${props => `rotateY(-90deg) translateZ(${props.size / 2 + 1}px)`};
  background: ${props => `url(${props.image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: ${props =>
    props.middle ? `solid 1.5px #fff` : `solid 1.5px #424242`};
  transition: border 1s ease;
  position: absolute;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  backface-visibility: hidden;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0);
  outline: 2px solid transparent;
`;

const CubeFace4 = styled.div`
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${props => `rotateY(90deg) translateZ(${props.size / 2 + 1}px)`};
  background: ${props => `url(${props.image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: ${props =>
    props.middle ? `solid 1.5px #fff` : `solid 1.5px #424242`};
  transition: border 1s ease;
  position: absolute;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  backface-visibility: hidden;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0);
  outline: 2px solid transparent;
`;

const CubeFace5 = styled.div`
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${props => `rotateX(90deg) translateZ(${props.size / 2 + 1}px)`};
  background: ${props => `url(${props.image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: ${props =>
    props.middle ? `solid 1.5px #fff` : `solid 1.5px #424242`};
  transition: border 1s ease;
  position: absolute;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  backface-visibility: hidden;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0);
  outline: 2px solid transparent;
`;

const CubeFace6 = styled.div`
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${props => `rotateX(270deg) translateZ(${props.size / 2 + 1}px)`};
  background: ${props => `url(${props.image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: ${props =>
    props.middle ? `solid 1.5px #fff` : `solid 1.5px #424242`};
  transition: border 1s ease;
  position: absolute;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  backface-visibility: hidden;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0);
  outline: 2px solid transparent;
`;

const Mask = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: 0.3;
  background-color: #000;
  width: 100%;
  height: 100%;
`;

const EventCube = ({ image, middle, size, isZoomed, num }) => {
  useEffect(() => {
    if (isZoomed) {
      TweenMax.set("#face1", {
        css: { backfaceVisibility: "initial" },
        delay: 1,
      });
      TweenMax.set("#face2", {
        css: { backfaceVisibility: "initial" },
        delay: 1,
      });
      TweenMax.set("#face3", {
        css: { backfaceVisibility: "initial" },
        delay: 1,
      });
      TweenMax.set("#face4", {
        css: { backfaceVisibility: "initial" },
        delay: 1,
      });
      TweenMax.set("#face5", {
        css: { backfaceVisibility: "initial" },
        delay: 1,
      });
      TweenMax.set("#face6", {
        css: { backfaceVisibility: "initial" },
        delay: 1,
      });
    } else {
      TweenMax.set("#face1", {
        css: { backfaceVisibility: "hidden" },
      });
      TweenMax.set("#face2", {
        css: { backfaceVisibility: "hidden" },
      });
      TweenMax.set("#face3", {
        css: { backfaceVisibility: "hidden" },
      });
      TweenMax.set("#face4", {
        css: { backfaceVisibility: "hidden" },
      });
      TweenMax.set("#face5", {
        css: { backfaceVisibility: "hidden" },
      });
      TweenMax.set("#face6", {
        css: { backfaceVisibility: "hidden" },
      });
    }
  }, [isZoomed]);

  useEffect(() => {
    if (middle === true) {
      TweenMax.to(`.cubeMask${num}`, 1, { opacity: "0.3" });
    } else if (middle === false) {
      TweenMax.to(`.cubeMask${num}`, 1, { opacity: "0.8" });
    }
  }, [middle]);

  return (
    <SmallContainer size={size}>
      <CubeContainer>
        <CubeFace1 id="face1" image={image} middle={middle} size={size}>
          <Mask className={`cubeMask${num}`} middle={middle} />
        </CubeFace1>
        <CubeFace2 id="face2" image={image} middle={middle} size={size}>
          <Mask className={`cubeMask${num}`} middle={middle} />
        </CubeFace2>
        <CubeFace3 id="face3" image={image} middle={middle} size={size}>
          <Mask className={`cubeMask${num}`} middle={middle} />
        </CubeFace3>
        <CubeFace4 id="face4" image={image} middle={middle} size={size}>
          <Mask className={`cubeMask${num}`} middle={middle} />
        </CubeFace4>
        <CubeFace5 id="face5" image={image} middle={middle} size={size}>
          <Mask className={`cubeMask${num}`} middle={middle} />
        </CubeFace5>
        <CubeFace6 id="face6" image={image} middle={middle} size={size}>
          <Mask className={`cubeMask${num}`} middle={middle} />
        </CubeFace6>
      </CubeContainer>
    </SmallContainer>
  );
};

export default EventCube;
